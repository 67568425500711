import React from "react";

function Footer() {
    return (
        <div>
            <footer className="main-footer">
                <strong>Copyright © Kracknow 2022 .</strong> All rights reserved.
            </footer>
        </div>
    )
}
export default Footer
export default {
  // server_url : "https://o7z8zek6f5.execute-api.ap-south-1.amazonaws.com/dev",
  server_url : "https://api.kracknow.com",
  //   server_url:
  //     "https://4uwwei55mc.execute-api.ap-south-1.amazonaws.com/prod",
  // server_url: "http://localhost:3000",
};

export const students_server_url =
  "https://4uwwei55mc.execute-api.ap-south-1.amazonaws.com/prod";

// export const students_server_url =
// "http://localhost:3000";
